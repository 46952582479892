import { Flex, Modal } from 'antd'
import React from 'react'

type Props = {
  open: boolean
  percent: number
  content: string
}
export default function LoadingModal(props: Props) {
  const { open, percent, content } = props
  return open ? (
    <div className='flex fixed justify-center items-center w-full h-[calc(100%-70px)] z-50'>
      <Flex
        align={'center'}
        justify='center'
        vertical
        className='backdrop-blur-[6px] p-40 rounded-lg'
      >
        <div className='w-[210px] h-[20px] bg-[#E3E3E3] rounded-xl relative'>
          <div
            className='absolute bg-[#2E9BFF] h-[12px] top-[50%] transform translate-y-[-50%] rounded-[6px] left-[5px] transition-all duration-500'
            style={{ width: Math.min(percent, 90) + '%' }}
          ></div>
        </div>
        <div className='text-xx font-bold mt-2'>{content}</div>
      </Flex>
    </div>
  ) : null
}
