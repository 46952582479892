import { Flex, Button } from 'antd'
import { useState } from 'react'

type Props = {
  imageResults: string[]
  originalImage: string
}

const ImgResultView = (props: Props) => {
  const { imageResults, originalImage } = props
  const [previewImg, setPreviewImg] = useState<string>(imageResults[0])
  const [selectedPhotoKey, setSelectedPhotoKey] = useState(0)

  const chooseImage = (image: string, key: number) => {
    setPreviewImg(image)
    setSelectedPhotoKey(key)
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div
        className='container m-5 flex rounded-lg'
        style={{
          margin: 30,
          overflow: 'hidden',
          background: 'white',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          paddingBottom: 30,
          paddingInline: 30,
          paddingTop: 30,
          flexDirection: 'column'
        }}
      >
        {' '}
        <div className='w-full h-[75%] flex flex-col justify-center content-center'>
          <img className='h-[450px] self-center' src={previewImg} alt='' />
          <Button className='w-32 self-center mt-5 text-white hidden bg-slate-900'>再生成する</Button>
        </div>
        <div className='w-full h-[25%] flex justify-center mt-7'>
          <Flex className='flex-nowrap overflow-hidden hover:overflow-x-auto border-2'>
            <img className='mx-3 self-start h-[150px]' src={originalImage} alt='' />
            {imageResults.map((image, key) => {
              return (
                <img
                  className={`mx-3 self-start h-[150px] ${
                    selectedPhotoKey === key ? 'border-cyan-500 border-solid' : ''
                  }`}
                  src={image}
                  onClick={() => chooseImage(image, key)}
                  alt=''
                  key={key}
                />
              )
            })}
          </Flex>
        </div>
      </div>
    </div>
  )
}

export default ImgResultView
