import { Col, Flex, Row, Select, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import CommonAPIs from '../../../controller/API/CommonAPIs'

const ProductForm = ({ onFinishSetupProductInfo, showCreateTemplateScreen, info, productImg }) => {
  const [templates, setTemplates] = useState([])
  const [templateSelected, setTemplateSelected] = useState(templates[0])
  const [productName, setProductName] = useState('')
  const [materialInfo, setMaterialInfo] = useState('')
  const [otherInfo, setOtherInfo] = useState('')
  const [sizeInfo, setSizeInfo] = useState('')
  const [image, setImage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [imageError, setImageError] = useState(null)

  useEffect(() => {
    if (info != null && info != undefined) {
      setProductName(info?.title ?? '')
      setMaterialInfo(info?.material ?? '')
      setOtherInfo(info?.other_specifications ?? '')
      setSizeInfo(info?.size ?? '')
      setTemplateSelected(info?.template ?? templates[0])
      setImage(productImg ?? null)
    }
  }, [info, productImg])

  useEffect(() => {
    getTemplates()
  }, [])

  const getTemplates = () => {
    CommonAPIs.getMyTemplates()
      .then((res) => {
        console.log('=========== templates:', res)
        setTemplates(res)
        if (Object.values(res).length > 0) {
          if (info?.template == null || info?.template == undefined) {
            setTemplateSelected(res[0])
          }
        }
      })
      .catch((error) => {
        console.log('Error getting templates:', error)
      })
  }

  const validateProductData = () => {
    if (image == '' || image == null || image == undefined) {
      setImageError('商品画像をアップロードしてください')
      return false
    } else {
      setImageError(null)
    }
    return true
  }

  const handleImageUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
      setImage(file)
      setImageError(null)
    }
  }

  const requestCreateProductContent = () => {
    // const a = {
    //   id: 3,
    //   template_id: '7',
    //   template: {
    //     id: 7,
    //     title: 'ddddddd',
    //     content: 'frwsdfsdfsdfsdfsdfds',
    //     thumbnail:
    //       'https://nft-snap-dev.s3.ap-northeast-1.amazonaws.com/template/%E5%8F%82%E7%85%A7%E7%94%BB%E5%83%8F20240617160851.png'
    //   },
    //   user_id: 2,
    //   user: {
    //     name: 'Mei',
    //     email: 'mai@gmail.com',
    //     description_checkpoint: '',
    //     segment_checkpoint: '',
    //     created_at: null
    //   },
    //   image_url: 'https://nft-snap-dev.s3.ap-northeast-1.amazonaws.com/%E6%96%B0%E5%95%86%E5%93%8120240617164010.png',
    //   content:
    //     '①参照画像の商品の紹介文と文字数: 19\n②新商品の紹介文のみ文字数: 37\n\n---\n\n【タイトル】:  リブ編みタートルネックセーター\n\n【素材】: 吸湿性と保温性に優れた高品質なウール\n\n【サイズ】: フリーサイズ\n\n【その他仕様】: 洗濯機洗い可能\n\n---\n\n新商品の紹介文:\nこのリブ編みタートルネックセーターは、高品質なウール素材を使用し、保温性と吸湿性に優れています。肌触りも柔らかく、長時間の着用でも快適です。デザインは無地でシンプルなため、どんなスタイルにも合わせやすく、一枚で様々なコーディネートを楽しめます。フィット感はタイトすぎず、程よいゆとりがあり、体のラインを美しく見せます。カラーのバリエーションも豊富で、自分のお気に入りを見つけることができます。袖口と裾はしっかりと編まれており、型崩れしにくく長持ちします。特に寒い季節には重宝する一品です。常にスタイリッシュでいたい方にぴったりのアイテムです。\n\n---\n\n紹介文の長さを前後50文字以内で一致させるために、この紹介文では19文字から37文字の範囲以内で調整しています。',
    //   material: 'eeee',
    //   size: 'rrrrrr',
    //   title: 'dddde',
    //   other_specifications: 'hhhh',
    //   created_at: '2024-06-17T07:40:20.000000Z'
    // }

    // onFinishSetupProductInfo(a)

    const validated = validateProductData()

    if (validated == false) {
      return
    }

    setIsLoading(true)
    CommonAPIs.createProductContent(productName, sizeInfo, materialInfo, otherInfo, image, templateSelected.id)
      .then((res) => {
        console.log('=========== createProductContent:', res)
        onFinishSetupProductInfo(res, image)
      })
      .catch((error) => {
        console.log('Error creating product content:', error)
      })
      .finally(() => setIsLoading(false))
  }

  const onClickNextStep = () => {
    requestCreateProductContent()
  }

  const handleSelectTemplate = (value) => {
    templates.find((item) => {
      if (item.id === value) {
        setTemplateSelected(item)
      }
    })
  }

  return (
    <Flex style={{ flexDirection: 'column', alignItems: 'center' }}>
      {isLoading && <Spin size='large' fullscreen />}
      <Flex style={{ flexDirection: 'column', width: '70%' }}>
        <h2>商品説明の文章を作成します</h2>
        <Flex
          style={{
            background: 'white',
            display: 'flex',
            flexDirection: 'column',
            padding: 20,
            paddingInlineEnd: 35,
            borderRadius: 12
          }}
        >
          <div className='form-group'>
            <div htmlFor='templateName' style={{ marginBottom: 10 }}>
              テンプレート名
            </div>
            <Row>
              <Select
                value={templateSelected?.title}
                style={{ flex: 1, height: 38, marginRight: 40 }}
                onChange={handleSelectTemplate}
              >
                {templates?.map((item) => (
                  <Select.Option key={item?.id} value={item?.id} name={item?.title}>
                    <p>{item?.title}</p>
                  </Select.Option>
                ))}
              </Select>
              <div style={{ flex: 1 }}>
                <button
                  style={{
                    width: 200,
                    height: 38,
                    background: 'white',
                    placeSelf: 'center',
                    borderRadius: 6,
                    border: '1px solid #323539',
                    color: 'black'
                  }}
                  onClick={showCreateTemplateScreen}
                >
                  新規テンプレート作成
                </button>
              </div>
            </Row>
          </div>
          <Flex style={{ display: 'flex', flexDirection: 'row', height: 380 }}>
            <Flex style={{ flex: 1, flexDirection: 'column', marginRight: 40 }}>
              <div style={{ marginBottom: 8 }}>新規画像を入力</div>
              <div className='image-upload' style={{ height: '92%', placeItems: 'self-start', marginBottom: 20 }}>
                <input type='file' onChange={handleImageUpload} />
                {!image && (
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      justifyContent: 'center',
                      placeContent: 'center'
                    }}
                  >
                    <div style={{ color: '#CCCCCC' }}>ここに画像をドロップ</div>
                    <div style={{ color: '#CCCCCC' }}>-または-</div>
                    <div style={{ color: '#CCCCCC' }}>クリックしてアップロード</div>
                  </div>
                )}
                {image && (
                  <img
                    src={URL.createObjectURL(image)}
                    alt='Uploaded'
                    style={{
                      height: '100%',
                      width: '100%',
                      objectFit: 'contain'
                    }}
                  />
                )}
              </div>
              {imageError && <div style={{ color: '#F35746', fontSize: 10, marginTop: 4 }}>{imageError}</div>}
            </Flex>
            <Flex style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <div className='form-group'>
                <div style={{ marginBottom: 8 }}>商品名(任意)</div>
                <input
                  type='text'
                  id='productName'
                  value={productName}
                  onChange={(event) => setProductName(event.target.value)}
                  placeholder='商品名を入力'
                />
              </div>
              <div className='form-group'>
                <div style={{ marginBottom: 8 }}>素材情報(任意)</div>
                <input
                  type='text'
                  id='materialInfo'
                  value={materialInfo}
                  onChange={(event) => setMaterialInfo(event.target.value)}
                  placeholder='素材情報を入力'
                />
              </div>
              <div className='form-group'>
                <div style={{ marginBottom: 8 }}>その他情報(任意)</div>
                <textarea
                  type='text'
                  id='otherInfo'
                  value={otherInfo}
                  onChange={(event) => setOtherInfo(event.target.value)}
                  placeholder='その他情報を入力'
                />
              </div>
              <div className='form-group'>
                <div style={{ marginBottom: 8 }}>サイズ情報(任意)</div>
                <input
                  type='text'
                  id='sizeInfo'
                  value={sizeInfo}
                  onChange={(event) => setSizeInfo(event.target.value)}
                  placeholder='サイズ情報を入力'
                />
              </div>
            </Flex>
          </Flex>
        </Flex>
        <button
          onClick={onClickNextStep}
          style={{
            marginTop: 40,
            width: 250,
            height: 40,
            background: '#323539',
            placeSelf: 'center',
            borderRadius: 6
          }}
        >
          作成する
        </button>
      </Flex>
    </Flex>
  )
}

export default ProductForm
