import { Flex, Row, Select } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../../hooks/AppContext'

const ProductContentForm = ({ showCreateTemplateScreen, showSetupProductInfoScreen, info }) => {
  const { showCopyCompleted } = useContext(AppContext)

  const copyProductContent = () => {
    navigator.clipboard.writeText(info?.content)
    showCopyCompleted()
    // window.alert('コピーしました')
  }

  return (
    <Flex style={{ flexDirection: 'column', alignItems: 'center' }}>
      <Flex style={{ flexDirection: 'column', width: '70%' }}>
        <h2>商品説明の文章を作成します</h2>
        <Flex
          style={{
            background: 'white',
            display: 'flex',
            flexDirection: 'column',
            padding: 20,
            paddingInlineEnd: 35,
            borderRadius: 12
          }}
        >
          <div className='form-group'>
            <div htmlFor='templateName' style={{ marginBottom: 10 }}>
              テンプレート名
            </div>
            <Row>
              <Select
                style={{ width: '47%', height: 38, marginRight: 20 }}
                disabled={true}
                value={info?.template?.title}
                // disabled={checkIsDisabled()}
                // value={checkPointSelected?.new_checkpoint}
                // onChange={handleSelectCheckpoint}
              >
                {/* {templates?.map((item) => (
                  <Select.Option key={item?.id} value={item.name} name={item.name}>
                    <p>{item?.name}</p>
                  </Select.Option>
                ))} */}
              </Select>
              <button
                style={{
                  width: 200,
                  height: 38,
                  background: 'white',
                  placeSelf: 'center',
                  borderRadius: 6,
                  border: '1px solid #323539',
                  color: 'black'
                }}
                onClick={() => {
                  showCreateTemplateScreen()
                }}
              >
                新規テンプレート作成
              </button>
            </Row>
          </div>
          <Flex style={{ display: 'flex', flexDirection: 'column' }}>
            <Flex style={{ flex: 1, flexDirection: 'column', marginRight: 40 }}>
              <div style={{ marginBottom: 8 }}>新規画像を入力</div>
              <div
                style={{
                  height: 241,
                  border: '2px dotted #CCCCCC',
                  borderRadius: 8,
                  alignItems: 'center',
                  display: 'flex',
                  paddingLeft: 10
                }}
              >
                <img
                  src={info?.image_url}
                  style={{ height: '92%', aspectRatio: 1, borderRadius: 8, objectFit: 'contain' }}
                />
              </div>
            </Flex>
            <Flex style={{ flex: 1, display: 'flex', flexDirection: 'column', marginTop: 20 }}>
              <div className='form-group'>
                <div style={{ marginBottom: 8 }}>生成結果</div>
                <textarea
                  type='text'
                  value={info?.content}
                  placeholder='その他情報を入力'
                  style={{
                    height: 241,
                    background: 'white'
                  }}
                  disabled
                />
              </div>
              <button
                style={{
                  width: 72,
                  height: 24,
                  background: 'white',
                  placeSelf: 'flex-end',
                  borderRadius: 6,
                  border: '1px solid #323539',
                  color: 'black',
                  padding: 0,
                  fontSize: 12
                }}
                onClick={copyProductContent}
              >
                コピー
              </button>
            </Flex>
          </Flex>
          <button
            style={{
              marginTop: 40,
              width: 250,
              height: 40,
              background: '#323539',
              placeSelf: 'center',
              borderRadius: 6
            }}
            onClick={() => showSetupProductInfoScreen()}
          >
            新しい説明を生成
          </button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ProductContentForm
