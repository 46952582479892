import { Button, Modal, Steps } from 'antd'
import ErrorIcon from '../../../assets/folder-error.svg'
type Props = {
  open: boolean
  onClose: () => void
  reSubmit: () => void
}
export default function TrainLoraErrorModal(props: Props) {
  const { onClose, open, reSubmit } = props
  return (
    <Modal
      title={<div className='text-center'>エラー</div>}
      open={open}
      onCancel={onClose}
      width={600}
      centered
      footer={[
        <div className='flex justify-center' key={'ok'}>
          <Button
            className='w-[198px] h-[40px] bg-[#323539] text-white'
            onClick={() => {
              onClose()
              reSubmit()
            }}
          >
            再学習
          </Button>
        </div>
      ]}
    >
      <div className='h-[220px] relative flex flex-col justify-center items-center'>
        <>
          <img src={ErrorIcon} className='mb-2' />
          <div className='text-base font-medium mb-4 text-center leading-[23.17px]'>
            ブランド学習に失敗しました。
            <br />
            再度学習してください。
          </div>
        </>
        <div className='bg-[#D3D3D3] w-[600px] left-[-24px] absolute h-[1px] bottom-0 mb-5'></div>
      </div>
    </Modal>
  )
}
