import React, { useContext, useEffect, useState } from 'react'
import { Input, Flex, Button } from 'antd'
import '../../controller/styles.css'
import logo from '../../assets/logo_big.png'
import { useNavigate } from 'react-router-dom'
import CommonAPIs from '../../controller/API/CommonAPIs'
import { AppContext } from '../../hooks/AppContext'
import Constant from '../../controller/Constant'

const LoginScreen = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const { setIsSpinning, showErrorApiAlert, setMenuSelected } = useContext(AppContext)

  const showHomeScreen = () => {
    setIsSpinning(true)
    CommonAPIs.login(email, password)
      .then((res) => {
        navigate('/')
        setMenuSelected(0)
        localStorage.setItem('token', res.data?.access_token)
        localStorage.setItem('email', email)
        localStorage.setItem(
          Constant.localStorageKeys.isHideChangeFabricIntro,
          res.data?.show_change_fabric == 1 ? 'false' : 'true'
        )
        localStorage.setItem(
          Constant.localStorageKeys.isHideImg2ImgIntro,
          res.data?.show_img2img == 1 ? 'false' : 'true'
        )
        localStorage.setItem(
          Constant.localStorageKeys.isHideLoraIntro,
          res.data?.show_train_lora == 1 ? 'false' : 'true'
        )
        localStorage.setItem(
          Constant.localStorageKeys.isHideTxt2ImgIntro,
          res.data?.show_txt2img == 1 ? 'false' : 'true'
        )
      })
      .catch((err) => showErrorApiAlert(err))
      .finally(() => setIsSpinning(false))
  }

  useEffect(() => {
    const access_token = localStorage.getItem('token')
    if (access_token) {
      navigate('/')
      setMenuSelected(0)
    }
  }, [])

  return (
    <Flex vertical justify='center' align='center'>
      <img className='logo-icon' width={142} src={logo} />
      <Flex vertical>
        <p>メールアドレス</p>
        <Input
          onChange={(text: any) => setEmail(text?.target?.value)}
          style={{ width: 410 * (window.innerWidth / 625), marginLeft: 30, maxWidth: 410 }}
        />
        <p>パスワード</p>
        <Input.Password
          type='password'
          onChange={(text: any) => setPassword(text?.target?.value)}
          style={{ width: 410 * (window.innerWidth / 625), marginLeft: 30, maxWidth: 410 }}
        />
      </Flex>
      <Button onClick={showHomeScreen} className='btn-login'>
        ログイン
      </Button>
    </Flex>
  )
}

export default LoginScreen
