import { Button, Checkbox, Col, Drawer, Flex, Modal, Row, Slider, Space, Typography, InputNumber, Input } from 'antd'
import React, { useState, useRef, useContext, useEffect, useCallback } from 'react'
import html2canvas from 'html2canvas'
import { fillTransparentWithBlackAndWhite, removeBackgroundWithMask } from '../utils/ImageHandler'
import brushIcon from '../../src/assets/icons/brush.svg'
import colorPickerIcon from '../../src/assets/icons/color-picker.svg'
import eraserIcon from '../../src/assets/icons/eraser.svg'
import undoIcon from '../../src/assets/icons/undo.svg'
import redoIcon from '../../src/assets/icons/redo.svg'
import resetIcon from '../../src/assets/icons/reset.svg'

export type GenerateImgType = {
  id: number
  url: string
}

type Props = {
  isVisibleInPaint: boolean
  closeInPaint: () => void
  mediaSelectedInfo?: string
  setMediaSelectedInfo?: any
  onApplyImage: any
  backgroundEnable?: boolean
  setMediaSelectedList?: any
  mediaSelectedList?: GenerateImgType[]
  isMediaListScreen?: boolean
}
const MODE = {
  BRUSH: 'brush',
  ERASER: 'eraser',
  COLOR_PICKER: 'color'
}
const InpaintScreen = (props: Props) => {
  const { isVisibleInPaint, closeInPaint, mediaSelectedInfo, onApplyImage } = props
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const [isDrawing, setIsDrawing] = useState(false)
  const [imageWidth, setImageWidth] = useState(0)
  const [imageHeight, setImageHeight] = useState(0)
  const [brushSize, setBrushSize] = useState(50)
  const [eraserSize, setEraserSize] = useState(50)
  const [currentColor, setCurrentColor] = useState('#000000')
  const [undoStack, setUndoStack] = useState<string[]>([])
  const [redoStack, setRedoStack] = useState<string[]>([])
  const [mode, setMode] = useState(MODE.BRUSH)

  console.log('undoStack:', undoStack)
  console.log('redoStack:', redoStack)

  const [showSliderBrush, setShowSliderBrush] = useState(false)
  const [showSliderEraser, setShowSliderEraser] = useState(false)

  const brushSliderRef: any = useRef(null)
  const eraserSliderRef: any = useRef(null)

  const onResetPaint = () => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d')
      if (ctx) {
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height)
      }
    }
  }

  const onResetAndClosePaint = () => {
    closeInPaint()
    onResetPaint()
  }

  const startDrawing = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (mode == MODE.COLOR_PICKER) return

    const { offsetX, offsetY } = event.nativeEvent
    setIsDrawing(true)
    const context = canvasRef.current?.getContext('2d')
    if (context) {
      context.save()
      if (mode == MODE.ERASER) {
        context.globalCompositeOperation = 'destination-out'
      } else {
        context.globalCompositeOperation = 'source-over'
        context.strokeStyle = currentColor
      }
      context.lineWidth = mode == MODE.BRUSH ? brushSize : eraserSize
      context.lineCap = 'round'
      context.lineJoin = 'round'
      context.beginPath()
      context.moveTo(offsetX, offsetY)
    }
  }

  const draw = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (!isDrawing) return
    const { offsetX, offsetY } = event.nativeEvent
    const context = canvasRef.current?.getContext('2d')
    if (context) {
      context.lineCap = 'round'
      context.lineJoin = 'round'
      context.lineTo(offsetX, offsetY)
      context.stroke()
    }
  }

  const endDrawing = () => {
    if (!isDrawing) return
    setIsDrawing(false)
    const context = canvasRef.current?.getContext('2d')
    if (context) {
      context.closePath()
      context.restore()
      const image = canvasRef.current?.toDataURL()
      if (image) {
        setUndoStack((prev) => [...prev, image])
        setRedoStack([])
      }
    }
  }

  const getMask = async () => {
    if (canvasRef.current) {
      const res = await fillTransparentWithBlackAndWhite(canvasRef.current.toDataURL())
      return res
    }
    return ''
  }

  const handleRenderAIImage = async () => {
    console.log(undoStack)

    if (undoStack.length > 0) {
      const selector = document.querySelector('#sourceImage')
      if (selector) {
        const combinedCanvas = await html2canvas(selector as HTMLElement, {
          width: imageWidth,
          height: imageHeight,
          scale: 1
        })
        const maskImage = await getMask()
        const newImage = await removeBackgroundWithMask(combinedCanvas.toDataURL(), maskImage)
        onApplyImage(newImage, maskImage)
      }
    }
    onResetAndClosePaint()
  }

  useEffect(() => {
    const img = new Image()
    img.src = mediaSelectedInfo || ''

    img.onload = () => {
      if (img.height > 768 || img.width > 576) {
        if (img.height > 768) {
          setImageHeight(768)
          setImageWidth((768 * img.width) / img.height)
        }
        if (img.width > 576) {
          setImageWidth(576)
          setImageHeight((576 * img.height) / img.width)
        }
      } else {
        setImageHeight(img.height)
        setImageWidth(img.width)
      }
    }
  }, [mediaSelectedInfo])

  const undo = () => {
    if (undoStack.length === 0) return
    const newUndoStack = [...undoStack]
    const lastImage = newUndoStack.pop()
    setUndoStack(newUndoStack)
    if (lastImage) {
      setRedoStack((prev) => [...prev, lastImage])
      restoreCanvas(newUndoStack[newUndoStack.length - 1])
    }
  }

  const redo = () => {
    if (redoStack.length === 0) return
    const newRedoStack = [...redoStack]
    const lastImage = newRedoStack.pop()
    setRedoStack(newRedoStack)
    if (lastImage) {
      setUndoStack((prev) => [...prev, lastImage])
      restoreCanvas(lastImage)
    }
  }

  const restoreCanvas = (image: string | undefined) => {
    const context = canvasRef.current?.getContext('2d')
    if (context) {
      context.clearRect(0, 0, canvasRef.current!.width, canvasRef.current!.height)
      if (image) {
        const img = new Image()
        img.src = image
        img.onload = () => {
          context.drawImage(img, 0, 0, canvasRef.current!.width, canvasRef.current!.height)
        }
      }
    }
  }

  const clearCanvas = () => {
    const context = canvasRef.current?.getContext('2d')
    if (context) {
      context.clearRect(0, 0, canvasRef.current!.width, canvasRef.current!.height)
      setUndoStack([])
      setRedoStack([])
    }
  }

  const handleBrushSizeChange = useCallback((value: number) => {
    setBrushSize(value)
  }, [])

  const handleEraserSizeChange = useCallback((value: number) => {
    setEraserSize(value)
  }, [])

  const onClickBrush = () => {
    setShowSliderBrush(!showSliderBrush)
    setShowSliderEraser(false)
    setMode(MODE.BRUSH)
  }

  const onClickEraser = () => {
    setShowSliderEraser(!showSliderEraser)
    setShowSliderBrush(false)
    setMode(MODE.ERASER)
  }

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (brushSliderRef.current && !brushSliderRef.current.contains(event.target)) {
        // Hide brush slider if click is outside
        setShowSliderBrush(false)
      }
      if (eraserSliderRef.current && !eraserSliderRef.current.contains(event.target)) {
        // Hide eraser slider if click is outside
        setShowSliderEraser(false)
      }
    }

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside)

    // Clean up event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <Modal
      open={isVisibleInPaint}
      onCancel={onResetAndClosePaint}
      footer={null}
      width={1000}
      centered
      style={{ marginTop: 30, marginBottom: 30 }}
    >
      <Flex vertical align='center'>
        <Flex className=' cursor-pointer'>
          <img src={undoIcon} onClick={undo} />
          <img src={redoIcon} onClick={redo} />
          <img src={resetIcon} onClick={clearCanvas} />
        </Flex>

        <Flex
          align='center'
          id='product'
          style={{
            width: imageWidth,
            height: imageHeight,
            background: 'white'
          }}
        >
          <img
            src={`${mediaSelectedInfo}`}
            alt=''
            style={{
              width: imageWidth,
              height: imageHeight,
              objectFit: 'contain'
            }}
            id='sourceImage'
          />
          {mediaSelectedInfo && (
            <>
              <canvas
                ref={canvasRef}
                width={imageWidth}
                height={imageHeight}
                onMouseDown={startDrawing}
                onMouseMove={draw}
                onMouseUp={endDrawing}
                onMouseLeave={endDrawing}
                style={{
                  cursor: 'grab',
                  aspectRatio: 3 / 4,
                  position: 'absolute',
                  zIndex: 1
                }}
              />
              <Flex
                vertical
                className='absolute top-1/2 left-[50px] transform -translate-y-1/2 cursor-pointer shadow-md p-4 z-20'
                gap={5}
              >
                <div className='relative'>
                  <img
                    src={brushIcon}
                    onClick={onClickBrush}
                    className={`rounded-lg hover:border border-solid hover:border-black ${
                      mode == MODE.BRUSH ? 'border border-black' : 'border-transparent'
                    }`}
                  />

                  <Flex
                    className='w-[251px] h-[63px] bg-white border border-black border-solid rounded-lg absolute left-[100px] top-[5px] z-10'
                    align='center'
                    justify='center'
                    gap={2}
                    style={{ display: showSliderBrush ? '' : 'none' }}
                    ref={brushSliderRef}
                  >
                    <div className='font-bold text-sm'>サイズ</div>
                    <Slider
                      onChange={handleBrushSizeChange}
                      max={100}
                      min={0}
                      style={{ width: 116 }}
                      value={brushSize}
                    />
                    <Input className='w-[41px] h-[24px] p-0 text-center' max={100} min={5} value={brushSize} readOnly />
                  </Flex>
                </div>

                <div className='relative'>
                  <img
                    src={eraserIcon}
                    onClick={onClickEraser}
                    className={`rounded-lg hover:border border-solid hover:border-black ${
                      mode == MODE.ERASER ? 'border border-black' : 'border-transparent'
                    }`}
                  />
                  <Flex
                    className='w-[251px] h-[63px] bg-white border border-black border-solid rounded-lg absolute left-[100px] top-[5px] z-10'
                    align='center'
                    justify='center'
                    gap={2}
                    style={{ display: showSliderEraser ? '' : 'none' }}
                    ref={eraserSliderRef}
                  >
                    <div className='font-bold text-sm'>サイズ</div>
                    <Slider
                      onChange={handleEraserSizeChange}
                      max={100}
                      min={0}
                      style={{ width: 116 }}
                      value={eraserSize}
                    />
                    <Input
                      className='w-[41px] h-[24px] p-0 text-center'
                      max={100}
                      min={5}
                      value={eraserSize}
                      readOnly
                    />
                  </Flex>
                </div>
                <Flex className='relative'>
                  <label htmlFor='colorPicker' className='cursor-pointer'>
                    <img
                      onClick={() => setMode(MODE.COLOR_PICKER)}
                      src={colorPickerIcon}
                      className={`rounded-lg hover:border border-solid hover:border-black ${
                        mode == MODE.COLOR_PICKER ? 'border border-black' : 'border-transparent'
                      }`}
                    />
                  </label>
                  <input
                    id='colorPicker'
                    type='color'
                    className='absolute top-full left-[100px] opacity-0 w-0 h-0'
                    value={currentColor}
                    onChange={(e) => setCurrentColor(e.target.value)}
                  />
                </Flex>
              </Flex>
            </>
          )}
        </Flex>
        <Flex vertical align='center'>
          <Row>
            <Button onClick={handleRenderAIImage} className='w-[256px] h-[40px] mt-4 mb-2 bg-[#323539] text-white'>
              完成
            </Button>
          </Row>
        </Flex>
      </Flex>
    </Modal>
  )
}

export default InpaintScreen
