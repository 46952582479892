import { useState, useLayoutEffect } from 'react'
import { Flex, Modal } from 'antd'
import CommonAPIs from '../../../controller/API/CommonAPIs'
import Constant from '../../../controller/Constant'

const Introduction = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true)
  const [notShowAgain, setNotShowAgain] = useState<boolean>(false)

  useLayoutEffect(() => {
    //const isShowModal = localStorage.getItem('is_show_img2img_introduction')
    //setIsModalOpen(isShowModal ? true : false)
  }, [])

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const updateShowIntroduction = () => {
    if (notShowAgain) {
      CommonAPIs.checkShowIntroPopup(Constant.functionType.changeFabric)
        .then((isShow) => {
          if (isShow) {
            localStorage.setItem(Constant.localStorageKeys.isHideChangeFabricIntro, 'true')
          }
        })
        .catch((err) => {
          console.log('======= check show intro lora: ', err)
        })
    }
    setIsModalOpen(false)
  }

  return (
    <Modal
      width={'42%'}
      className='ml-[12%]'
      centered
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer
      mousePosition={{ x: window.innerWidth / 2, y: window.innerHeight / 2 }}
    >
      <div className='w-full flex my-[24px]'>
        <img
          className='mt-5 w-full self-center justify-self-center'
          src={require('../../../assets/fabric_introduction.png')}
          alt='img2img_introduction_2'
        />
      </div>
      <span className='font-bold text-[16px]'>生地シミュレーション</span>
      <p className='font-bold text-[13px]'>
        手書きのラフ画をアップロードし、説明文を入力すると、それに基づいたリアルなデザインを作成します。
        <br />
        アイディアを具体化するのに最適なツールです。
      </p>
      <Flex className='flex-col mt-16' justify='center'>
        <Flex justify='center'>
          <input type='checkbox' onChange={() => setNotShowAgain((prev) => !prev)} />
          <span className='ml-2 text-[13px] font-semibold'>次回からこのメッセージを表示しない</span>
        </Flex>
        <button
          className='w-[256px] h-[40px] text-[14px] bg-neutral-800 text-white rounded-md border-0 p-2 self-center mt-6 cursor-pointer'
          onClick={updateShowIntroduction}
        >
          閉じる
        </button>
      </Flex>
    </Modal>
  )
}

export default Introduction
