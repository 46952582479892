import { Flex, Progress } from 'antd/lib'
import { useEffect, useState } from 'react'

type Prop = {
  description: string
}

export default function ProgressComponent(prop: Prop) {
  const [percent, setPercent] = useState(0)
  useEffect(() => {
    const timerId = setInterval(() => {
      setPercent((prevState) => {
        if (prevState < 95) {
          return prevState + 1
        }
        return prevState
      })
      return
    }, 500)

    return () => {
      clearInterval(timerId)
    }
  }, [])

  return (
    <>
      <p className='text-center text-[12px] font-bold mt-10'>{prop.description}</p>
      <Flex gap='small' vertical className='mt-10 w-full' align='center'>
        <div className='flex flex-col justify-center content-center h-[190px] w-[324px] bg-white rounded-2xl'>
          <Progress status='active' percent={percent} className='w-[210px] h-[20px] self-center' showInfo={false} />
          <br />
          <span className='font-bold self-center'>画像生成中</span>
        </div>
      </Flex>
    </>
  )
}
