import { Layout } from 'antd'
import Sider from 'antd/es/layout/Sider'
import DefaultContent from './components/DefaultContent'
import Introduction from './components/Introduction'
import SelectCheckpoint from './components/SelectCheckpoint'
import SelectLora from './components/SelectLora'
import { useState, useContext, useEffect } from 'react'
import Description from './components/Description'
import BatchSize from './components/BatchSize'
import Txt2imgAPIs from '../../controller/API/Txt2imgAPIs'
import { AppContext } from '../../hooks/AppContext'
import Progress from './components/Progress'
import ImgsLayout from '../../components/Img2Img/components/ImgsLayout'
import Constant from '../../controller/Constant'
import { useThreadStore } from '../../controller/store'
import LoadingModal from '../../components/common/LoadingModal'

const { Content } = Layout

export default function TrainLoraScreen() {
  const { showErrorApiAlert } = useContext(AppContext)
  const [checkpoint, setCheckpoint] = useState({ new_checkpoint: '', name: '' })
  const [loras, setLoras] = useState<any[]>([])
  const [loraInvalid, setLoraInvalid] = useState<boolean>(false)
  const [description, setDescription] = useState<string>('')
  const [batchSize, setBatchSize] = useState('1')
  const [creatingImg, setCreatingImg] = useState(false)
  const [resultImgs, setResultImgs] = useState<{ id: number; url: string }[]>([])
  const [isShowIntroduction, setIsShowIntroduction] = useState<boolean>(false)

  const { setScreenKey } = useThreadStore()

  const checkShowIntroduction = () => {
    const isHideLoraIntro = localStorage.getItem(Constant.localStorageKeys.isHideTxt2ImgIntro)
    setIsShowIntroduction(isHideLoraIntro === 'true' ? false : true)
    localStorage.setItem(Constant.localStorageKeys.isHideTxt2ImgIntro, 'true')
  }

  const getImages = () => {
    return resultImgs.map((img, index) => {
      return img.url
    })
  }

  const handleGenerateTxt2img = () => {
    let lorasValue = loras.map((lora) => lora.name + ':' + lora.value)
    let params = {
      checkpoint: checkpoint?.new_checkpoint,
      batch_size: batchSize,
      description: description,
      loras: lorasValue
    }

    Txt2imgAPIs.sendApiTxt2img(params)
      .then((data) => {
        setCreatingImg(true)
        setResultImgs([])
        const intervalId = setInterval(() => {
          Txt2imgAPIs.checkJobStatus(data.job_id).then((result) => {
            if (result.status === 3) {
              setCreatingImg(false)
              setResultImgs(result.medias)
              clearInterval(intervalId)
            }
          })
        }, 5000)
      })
      .catch((error) => {
        showErrorApiAlert(error)
      })

    setCreatingImg(false)
  }

  useEffect(() => {
    checkShowIntroduction()
    setScreenKey(Constant.keyScreen.txt2img)
  }, [])

  const isDisabled = !checkpoint.name || !description || loraInvalid || creatingImg
  return (
    <div className='flex'>
      <Layout style={{ minHeight: window.innerHeight, paddingRight: 10 }}>
        <Sider width={295} style={{ background: '#f5f5f5' }}>
          <div className='w-[295px] h-full bg-white pl-5 mb-1 pt-3'>
            <SelectCheckpoint disabled={creatingImg} setCheckpoint={(checkpoint: any) => setCheckpoint(checkpoint)} />
            <SelectLora
              disabled={creatingImg}
              setLoras={(loras: any) => setLoras(loras)}
              loraInvalid={loraInvalid}
              setLoraInvalid={(value: boolean) => setLoraInvalid(value)}
            />
            <Description setDescription={(value: string) => setDescription(value)}></Description>
            <BatchSize setBatchSize={(val) => setBatchSize(val)}></BatchSize>
            <button
              disabled={isDisabled}
              style={{ background: isDisabled ? '#d9d9d9' : '#323539', cursor: isDisabled ? 'not-allowed' : 'pointer' }}
              className='text-[14px] w-[256px] text-white bg-[#323539] border-0 p-3 rounded-md cursor-pointer'
              onClick={() => handleGenerateTxt2img()}
            >
              作成する
            </button>
          </div>
        </Sider>
        {resultImgs.length > 0 ? (
          <ImgsLayout profile={description} images={getImages()} />
        ) : (
          <Content>
            {creatingImg ? <Progress description={description}></Progress> : <DefaultContent></DefaultContent>}
          </Content>
        )}
        {isShowIntroduction && <Introduction />}
      </Layout>
    </div>
  )
}
