import { CloseOutlined, PlusSquareOutlined } from '@ant-design/icons'
import { useEffect, useState, useContext } from 'react'
import Txt2ImgAPIs from '../../../controller/API/Txt2imgAPIs'
import { AppContext } from '../../../hooks/AppContext'
import { Typography } from 'antd'
import { formatStringDateWithType } from '../../../utils/utils'

const { Paragraph } = Typography

type Prop = {
  setLoras: (loras: any) => void
  loraInvalid: boolean
  setLoraInvalid: (value: boolean) => void
  disabled: boolean
}

export default function SelectLora(prop: Prop) {
  const [openLoraModal, setOpenLoraModal] = useState(false)
  const [selectedLoras, setSelectedLoras] = useState<any[]>([])
  const [checkedLoras, setCheckedLoras] = useState<any[]>([])
  const [loraValues, setLoraValues] = useState<any[]>([])
  const [isShowSelectedLoras, setIsShowSelectedLoras] = useState<boolean>(false)
  const [allLoras, setAllLoras] = useState([{ name: '', created_at: '', image: '', id: 0, value: 0 }])
  const { showErrorApiAlert } = useContext(AppContext)

  const handleCheck = (val: string) => {
    setCheckedLoras((prev: any) => {
      const isChecked = prev.includes(val)
      if (isChecked) {
        return prev.filter((item: any) => item !== val)
      } else {
        return [...prev, val]
      }
    })
  }

  useEffect(() => {
    Txt2ImgAPIs.getLoras()
      .then((data) => {
        setAllLoras(data)
      })
      .catch((error) => {
        showErrorApiAlert(error)
      })
  }, [])

  useEffect(() => {
    let selectedLorasCopy = [...selectedLoras]
    let oldLoras = loraValues.filter((item: any) => {
      if (selectedLorasCopy.includes(item.name)) {
        let index = selectedLorasCopy.indexOf(item.name)
        selectedLorasCopy.splice(index, 1)
        return true
      }
    })

    let newLoras = allLoras.filter((lora) => {
      if (selectedLorasCopy.includes(lora.name)) {
        lora.value = 0
        return lora
      }
    })

    setLoraValues([...oldLoras, ...newLoras])
    setCheckedLoras(selectedLoras)
  }, [selectedLoras])

  useEffect(() => {
    prop.setLoras(loraValues)
  }, [loraValues])

  const handleSelectedLoras = () => {
    setSelectedLoras(checkedLoras)
    setOpenLoraModal(false)
    setIsShowSelectedLoras(true)
  }

  const handleChangeLoraValue = (loraName: string, value: any) => {
    if (prop.disabled) return
    let totalValue = 0
    let editedLora = { name: '', value: 0 }
    loraValues.map((lora) => {
      if (lora.name === loraName) {
        totalValue += Number(value)
        editedLora = lora
      } else {
        totalValue += Number(lora.value)
      }
    })
    editedLora.value = value
    setLoraValues([...loraValues])
    if (totalValue > 1) {
      prop.setLoraInvalid(true)
      return
    } else {
      prop.setLoraInvalid(false)
    }
  }

  const handleRemoveLora = (loraName: string) => {
    if (prop.disabled) return
    setSelectedLoras(() => {
      const result = selectedLoras.filter((lora) => lora !== loraName)
      return [...result]
    })
  }

  return (
    <>
      <div className='text-sm font-bold mb-2'>LoRA</div>

      {isShowSelectedLoras && loraValues.length ? (
        <div className='w-[256px] h-auto flex-col bg-[#F6F6F6] relative rounded-md flex justify-between items-center mb-5'>
          <div className='w-full flex justify-end p-2'>
            <PlusSquareOutlined
              className='text-lg mr-2 cursor-pointer'
              onClick={() => {
                if (prop.disabled) return
                setOpenLoraModal(true)
                setCheckedLoras(selectedLoras)
              }}
            />
          </div>
          {loraValues.map((lora) => (
            <div className='w-full flex p-2 mb-2' key={lora.name}>
              <img className='w-[60px] h-[60px] ' src={lora.image} alt='' style={{ objectFit: 'contain' }} />
              <div className='flex flex-col ml-2'>
                <div className='text-[12px] font-light'>{formatStringDateWithType(lora.created_at, 'YYYY/MM/DD')}</div>
                <Paragraph
                  className='text-[12px] font-semibold'
                  ellipsis={{
                    rows: 2,
                    expandable: false
                  }}
                >
                  LoRA: {lora.name}
                </Paragraph>
                <input
                  type='range'
                  min='0'
                  max='1'
                  step={0.1}
                  defaultValue={0}
                  onChange={(e) => handleChangeLoraValue(lora.name, e.target.value)}
                  className='mt-auto'
                  disabled={prop.disabled}
                />
              </div>
              <div className='flex flex-col justify-center justify-items-center'>
                <div className='flex justify-center hover:cursor-pointer' onClick={() => handleRemoveLora(lora.name)}>
                  <img
                    className='w-[17px] h-[15px] justify-self-center'
                    src={require('../../../assets/trash.png')}
                    alt='trash'
                  />
                </div>
                <div className='w-[41px] h-[24px] mt-auto flex content-center justify-center border-[1px] border-solid rounded-md border-[#6AAFE6]'>
                  <span className='self-center'>{lora.value}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div
          className='w-[256px] h-[60px] bg-[#F6F6F6] relative rounded-md flex justify-between items-center mb-5 cursor-pointer'
          onClick={() => {
            setOpenLoraModal(true)
            setCheckedLoras(selectedLoras)
          }}
        >
          <label className='ml-5 text-sm font-normal max-w-[80%]'>LoRAを追加</label>
          <PlusSquareOutlined className='text-lg mr-2' />
        </div>
      )}

      {prop.loraInvalid && (
        <span className='text-[10px] text-[#F35746]'>※ブランドのウェイト設定は合計で最大1に調整してください。</span>
      )}

      <div
        className='bg-white fixed left-[325px] w-[354px] h-[80%] top-[10%] overflow-y-auto overflow-x-hidden p-5'
        style={{
          display: openLoraModal ? '' : 'none'
        }}
      >
        <div className='flex'>
          <div className='flex flex-col mb-2 justify-between w-[105%]'>
            <label className='font-bold text-[14px]'>LoRAを選択してください</label>
            <ul className='text-[10px] pl-4'>
              <li>複数選択可能</li>
              <li>LoRAは2～3つまで選択することを推奨します。</li>
              <li>選びすぎると品質が低下する可能性があります。</li>
            </ul>
          </div>
          <CloseOutlined className='text-lg cursor-pointer self-start' onClick={() => setOpenLoraModal(false)} />
        </div>
        {allLoras.map((lora) => (
          <div
            className='w-[256px] bg-[#F6F6F6] rounded p-2 relative flex cursor-pointer hover:border border-solid border-transparent hover:border-black mb-2'
            style={{ width: 314 }}
          >
            <img className='w-[60px] h-[60px] ' src={lora.image} alt='lora' style={{ objectFit: 'contain' }} />
            <div className='flex flex-col ml-2'>
              <div className='text-[12px] font-light'>{formatStringDateWithType(lora.created_at, 'YYYY/MM/DD')}</div>
              <Paragraph
                className='text-[12px] font-semibold'
                ellipsis={{
                  rows: 2,
                  expandable: false
                }}
              >
                LoRA: {lora.name}
              </Paragraph>
            </div>
            <div className='ml-auto flex'>
              <input
                type='checkbox'
                checked={checkedLoras.includes(lora.name)}
                value={lora.name}
                className='self-center'
                onChange={() => handleCheck(lora.name)}
              />
            </div>
          </div>
        ))}
        <div className='w-[314px] flex justify-center'>
          <button
            className='w-[256px] h-[40px] bg-[#323539] text-white border-0 rounded-md text-[14px]'
            onClick={handleSelectedLoras}
          >
            確定
          </button>
        </div>
      </div>
    </>
  )
}
