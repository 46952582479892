import { Button, Col, Flex, Image, Row, Space, Typography } from 'antd'
import React, { useContext, useState } from 'react'
import Constant from '../../../controller/Constant'
import { AppContext } from '../../../hooks/AppContext'

type Props = {
  handleRequestImg2Img: (value: number) => void
  systemPromptType: number
  isBlockSelectPrompt: boolean
}

const SystemPromptOptions: React.FC<Props> = (props) => {
  const { systemPromptType, handleRequestImg2Img, isBlockSelectPrompt } = props
  const { isMobile } = useContext(AppContext)

  const isActive = (type: number) => {
    if (systemPromptType == type) {
      return true
    }
    return false
  }

  const SystemPromptItem = ({ title, systemPromptType }: { title: string; systemPromptType: number }) => {
    return (
      <Row>
        <Button
          className='mb-4 font-bold text-left whitespace-pre-line h-auto border-none px-2 py-3 rounded-[10px]'
          onClick={() => handleRequestImg2Img(systemPromptType)}
          style={{
            background: isBlockSelectPrompt ? (isActive(systemPromptType) ? '#323539' : 'white') : 'white',
            color: isBlockSelectPrompt ? (isActive(systemPromptType) ? 'white' : 'black') : 'black'
          }}
          disabled={isBlockSelectPrompt ? true : false}
        >
          {title}
        </Button>
      </Row>
    )
  }

  return (
    <Col>
      <Row className='items-center'>
        <img src={Constant.icon.bot} className='h-[44px] aspect-square mr-5' />
        <Typography
          style={{
            fontSize: 20,
            fontWeight: 'bold'
          }}
        >
          どのようなスタイルで画像を生成するか選択してください。
        </Typography>
      </Row>
      <Col style={{ marginLeft: isMobile ? 0 : 65, marginTop: 20 }}>
        <SystemPromptItem title='写真の要素をインスパイアー' systemPromptType={Constant.systemPromptType.option1} />
        <SystemPromptItem
          title='写真のプロダクトの要素を落とし込む'
          systemPromptType={Constant.systemPromptType.option2}
        />
        <SystemPromptItem
          title={`アップロードされた画像に合う\nブランドプロフィール用の商品を作るプロンプト`}
          systemPromptType={Constant.systemPromptType.option3}
        />
        <SystemPromptItem
          title={`生地の写真をアップしたら\nプロフィールに合致する画像を作成する`}
          systemPromptType={Constant.systemPromptType.option4}
        />
      </Col>
    </Col>
  )
}

export default SystemPromptOptions
