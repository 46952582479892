import { CloseOutlined, PlusSquareOutlined } from '@ant-design/icons'
import ClickAwayListener from 'react-click-away-listener'
import useImg2Img from '../../../hooks/useImg2Img'
import React, { useContext, useEffect, useState } from 'react'
import Constant from '../../../controller/Constant'
import CheckpointItem from '../../../components/Checkpoint/CheckpointItem'
import { Button, Flex, Input, Select, Typography } from 'antd'
import { Modal } from 'antd'
import { useThreadStore } from '../../../controller/store'
import { AppContext } from '../../../hooks/AppContext'
type Props = {
  jobRunning: boolean
}
export default function Img2ImgSidebar({ jobRunning }: Props) {
  const { windowWidth } = useContext(AppContext)
  const [openCheckPointModal, setOpenCheckPointModal] = useState(false)
  const { checkpointImg2ImgSelected, setBatchSizeSelected, batchSizeSelected, threadSelectedID, countNewThread } =
    useThreadStore()
  const [activeCheckpoint, setActiveCheckpoint] = useState<any>(null)

  const {
    getNewCheckpoint,
    checkPoints,
    handleSelectCheckpoint,
    getCheckpointProfile,
    checkpointProfile,
    isShowProfileModal,
    updateCheckpointProfile,
    handleCancelSaveProfile,
    setCheckpointProfileEdited,
    checkpointProfileEdited,
    setIsShowProfileModal
  } = useImg2Img()

  const onSelect = (checkpoint: any) => {
    if (!checkpoint) return
    setOpenCheckPointModal(false)
    handleSelectCheckpoint(checkpoint)
  }

  useEffect(() => {
    getNewCheckpoint()
  }, [])

  useEffect(() => {
    if (checkpointImg2ImgSelected?.new_checkpoint) {
      getCheckpointProfile()
    }
  }, [checkpointImg2ImgSelected?.new_checkpoint])

  useEffect(() => {
    if (checkPoints && checkPoints.length > 0) {
      setActiveCheckpoint(checkPoints[0])
    }
  }, [checkPoints])
  useEffect(() => {
    if (checkpointImg2ImgSelected && checkpointImg2ImgSelected.name) {
      setActiveCheckpoint(checkpointImg2ImgSelected)
    }
  }, [checkpointImg2ImgSelected])

  useEffect(() => {
    setOpenCheckPointModal(false)
  }, [threadSelectedID, countNewThread])

  return (
    <>
      {jobRunning && (
        <div
          className='fixed h-full bg-[#d9d9d9a1] cursor-not-allowed pl-5 mb-1 pt-3 z-10'
          style={{ width: windowWidth * (295 / 1440), maxWidth: 295 }}
        ></div>
      )}
      <div style={{ width: windowWidth * (295 / 1440), maxWidth: 295 }} />
      <div
        className='fixed h-full bg-white pl-5 mb-1 pt-3 '
        style={{ width: windowWidth * (295 / 1440), maxWidth: 295 }}
      >
        <div className='text-sm font-bold mb-2'>
          生成モデルを選択 <label className='text-[#F35746] text-[10px] font-normal ml-2'>必須</label>
        </div>
        {checkpointImg2ImgSelected.new_checkpoint ? (
          <CheckpointItem
            data={checkpointImg2ImgSelected}
            onOpenReselect={() => setOpenCheckPointModal(true)}
            style={{ maxWidth: 256, width: 'auto' }}
          />
        ) : (
          <div className='h-[42px] bg-[#F6F6F6] rounded-lg flex justify-between items-center mb-5 mr-5'>
            <label className='ml-5 text-xs font-medium'>モデルを設定</label>
            <PlusSquareOutlined className='text-lg mr-2 cursor-pointer' onClick={() => setOpenCheckPointModal(true)} />
          </div>
        )}
        {checkpointImg2ImgSelected.new_checkpoint && (
          <>
            <div className='text-sm font-bold mb-2'>プロフィール</div>
            <div className='max-w-[256px]  bg-[#F6F6F6] relative rounded-lg flex justify-between items-center mb-5 mr-5'>
              <Typography
                className='ml-5 text-sm font-normal max-w-[80%] py-2'
                style={{ color: checkpointProfile?.profile_jp ? 'black' : 'red' }}
              >
                {checkpointProfile?.profile_jp ? checkpointProfile?.profile_jp : 'プロフィールを入力してください。'}
              </Typography>
              <img
                src={Constant.icon.edit}
                className='h-6 aspect-square absolute right-2 top-1/2 transform -translate-y-1/2 text-2xl cursor-pointer'
                onClick={() => setIsShowProfileModal(true)}
              />
            </div>
          </>
        )}

        <Flex
          align='center'
          justify='center'
          className='fixed bottom-10'
          style={{ width: windowWidth * (256 / 1440), maxWidth: 256 }}
        >
          <Typography className='font-bold'>画像作成</Typography>
          <Select
            className='ml-5 justify-center items-center text-center'
            value={batchSizeSelected}
            onChange={(value: number) => setBatchSizeSelected(Constant.batchSizeImg2Img[value - 1])}
            bordered={false}
          >
            {Constant.batchSizeImg2Img.map((item: number) => (
              <Select.Option value={item}>
                <p className='font-bold text-[12px]'>{`${item}枚`}</p>
              </Select.Option>
            ))}
          </Select>
        </Flex>
      </div>

      <div
        className='bg-white fixed left-[300px] w-[354px] h-[500px] top-[115px] z-10 rounded-xl'
        style={{
          display: openCheckPointModal ? '' : 'none'
        }}
      >
        <div className='h-[440px] overflow-x-hidden'>
          <div className='flex mb-4 justify-between pl-5 pt-5 pr-5'>
            <label className='text-sm font-bold'>生成モデルを選択</label>
            <CloseOutlined className='text-lg cursor-pointer' onClick={() => setOpenCheckPointModal(false)} />
          </div>
          <div className='bg-[#EEEEF4] w-full h-[2px] z-[20] left-0 top-[45px]'></div>
          <div className='h-[85%] overflow-y-auto overflow-x-hidden p-5 relative'>
            {checkPoints.map((x: any) => (
              <CheckpointItem
                key={x.name}
                data={x}
                activeCheckpoint={activeCheckpoint || checkpointImg2ImgSelected}
                onSelect={() => setActiveCheckpoint(x)}
                style={{ width: 314 }}
              />
            ))}
          </div>
        </div>
        <Flex align='center' justify='center'>
          <Button
            className='w-[256px] h-[40px] bg-[#323539] rounded-md text-white text-sm font-medium mt-2'
            onClick={() => onSelect(activeCheckpoint)}
          >
            &#8203;確定
          </Button>
        </Flex>
      </div>

      <Modal
        title='プロフィール'
        open={isShowProfileModal}
        onOk={updateCheckpointProfile}
        onCancel={handleCancelSaveProfile}
        cancelText='キャンセル'
        okText='保存する'
        okButtonProps={{ style: { backgroundColor: '#323539', color: 'white' } }}
      >
        <Input.TextArea
          value={checkpointProfileEdited}
          onChange={(e) => setCheckpointProfileEdited(e.target.value)}
          style={{
            resize: 'none',
            height: 80,
            scrollbarWidth: 'none'
          }}
        />
      </Modal>
    </>
  )
}
