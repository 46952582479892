import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Carousel, Col, Flex, Image, List, Modal, Row, Space } from 'antd'
import type { CarouselRef } from 'antd/es/carousel'
import {
  BorderOutlined,
  CheckSquareOutlined,
  CloseOutlined,
  LeftCircleOutlined,
  LeftOutlined,
  RightCircleOutlined,
  RightOutlined
} from '@ant-design/icons'
import { AppContext } from '../../../hooks/AppContext'
import { saveAs } from 'file-saver'
import { v4 as uuidv4 } from 'uuid'
import DownloadIcon from '../../../assets/ic-download.svg'

type Props = {
  imageList: { url: string }[]
  indexPreview: number
  isPreview: boolean
  setIsPreview: (value: boolean) => void
}

const PreviewImagesModal: React.FC<Props> = (props) => {
  const { imageList, setIsPreview, isPreview, indexPreview } = props
  const { isMobile, showErrorApiAlert } = useContext(AppContext)
  const carouselRef = useRef<CarouselRef | null>()
  const handleClose = () => setIsPreview(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [imgsSelected, setImgsSelected] = useState<{ url: string }[]>([])

  const onPreviousCarouselClick = () => {
    if (currentIndex == 0) {
      setCurrentIndex(imageList.length - 1)
      return
    }
    setCurrentIndex(currentIndex - 1)
  }

  const onNextCarouselClick = () => {
    console.log('=========: ', currentIndex)
    if (currentIndex == imageList.length - 1) {
      setCurrentIndex(0)
      return
    }
    setCurrentIndex(currentIndex + 1)
  }

  const handleDownloadImage = async (imageUrl: string) => {
    try {
      const response = await fetch(imageUrl + '?not-from-cache-please')
      const blob = await response.blob()

      const fileName = uuidv4()
      saveAs(blob, fileName)
    } catch (error) {
      showErrorApiAlert(error)
    }
  }

  const handleDownloadListImg = (item: { url: string }[]) => {
    for (let index = 0; index < item?.length; index++) {
      handleDownloadImage(item[index]?.url)
    }
  }

  const handleAfterChange = (current: number) => {
    setCurrentIndex(current)
  }

  useEffect(() => {
    setCurrentIndex(indexPreview)
  }, [indexPreview])

  return (
    <>
      {isPreview && (
        <Flex vertical id='myModal' className='modal' justify='center' align='center' style={{ zIndex: 999 }}>
          <Flex
            style={{
              background: 'white',
              width: '73%',
              // height: '80%',
              borderRadius: 9
            }}
            vertical
          >
            <CloseOutlined
              style={{
                marginTop: 16,
                fontSize: 24,
                alignSelf: 'flex-end',
                marginRight: 16
              }}
              onClick={handleClose}
            />
            <Flex style={{ alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>
              <LeftOutlined
                onClick={onPreviousCarouselClick}
                style={{
                  marginRight: 8
                }}
              />
              <Image
                src={imageList[currentIndex]?.url}
                style={{ height: 434, width: 434, objectFit: 'contain' }}
                preview={false}
              />
              <RightOutlined
                onClick={onNextCarouselClick}
                style={{
                  marginLeft: 8
                }}
              />
            </Flex>
            <Row style={{ justifyContent: 'center', marginTop: 30 }}>
              <List
                dataSource={imageList}
                renderItem={(item) => (
                  <>
                    <img
                      src={DownloadIcon}
                      style={{
                        position: 'absolute',
                        width: 18,
                        height: 18,
                        marginLeft: 6,
                        marginTop: 6,
                        marginInlineStart: 112
                      }}
                      onClick={() => handleDownloadImage(item.url)}
                    />
                    {/* {imgsSelected.includes(item) ? (
                      <CheckSquareOutlined
                        style={{
                          position: 'absolute',
                          fontSize: 16,
                          marginLeft: 6,
                          marginTop: 6
                        }}
                        onClick={() => {
                          setImgsSelected(imgsSelected.filter((i) => i.url !== item.url))
                        }}
                      />
                    ) : (
                      <BorderOutlined
                        style={{
                          position: 'absolute',
                          fontSize: 16,
                          marginLeft: 6,
                          marginTop: 6
                        }}
                        onClick={() => {
                          setImgsSelected([...imgsSelected, item])
                        }}
                      />
                    )} */}
                    <img
                      draggable={false}
                      style={{
                        width: 136,
                        height: 136,
                        marginRight: 8,
                        borderRadius: 8,
                        objectFit: 'cover'
                      }}
                      src={item?.url}
                    />
                  </>
                )}
                itemLayout='horizontal'
                style={{ whiteSpace: 'nowrap', width: 568 }}
                className='flex-nowrap overflow-hidden hover:overflow-x-auto'
                rowKey={(item) => item.url}
              />
            </Row>
            <Row style={{ justifyContent: 'center', marginTop: 8, marginBottom: 20 }}>
              <Button
                onClick={() => handleDownloadListImg(imageList)}
                type='primary'
                className='w-[256px] h-[40px] font-bold bg-[#323539] mb-3 mt-3'
              >
                画像を一括保存
              </Button>
            </Row>
          </Flex>
        </Flex>
      )}
    </>
  )
}

export default PreviewImagesModal
