import { create } from 'zustand'
import { IThreadList } from '../../types/thread'
import { CheckpointProfileType, NewCheckpointType } from '../../types'

interface IThreadImg2ImgStore {
  screenKey: number
  threadSelectedID: number
  newThreadID: number
  threadList: IThreadList[]
  batchSizeSelected: number
  checkpointImg2ImgSelected: NewCheckpointType
  checkpointProfile: CheckpointProfileType
  editThreadNameID: number
  countNewThread: number
  setScreenKey: (screenKey: number) => void
  setThreadList: (threadList: IThreadList[]) => void
  setThreadSelectedID: (threadSelectedID: number) => void
  setNewThreadID: (newThreadID: number) => void
  setBatchSizeSelected: (BatchSizeSelected: number) => void
  setCheckpointImg2ImgSelected: (checkpointImg2ImgSelected: NewCheckpointType) => void
  setCheckpointProfile: (checkpointProfile: CheckpointProfileType) => void
  setEditThreadNameID: (editThreadNameID: number) => void
  setCountNewThread: (countNewThread: number) => void
}

export const useThreadStore = create<IThreadImg2ImgStore>((set) => ({
  screenKey: 0,
  threadList: [],
  threadSelectedID: 0,
  newThreadID: 0,
  batchSizeSelected: 1,
  checkpointImg2ImgSelected: {
    name: '',
    new_checkpoint: '',
    first_image: ''
  },
  checkpointProfile: {
    id: 0,
    checkpoint: '',
    profile_en: '',
    profile_jp: ''
  },
  editThreadNameID: 0,
  countNewThread: 0,
  setScreenKey: (screenKey: number) => {
    set({ screenKey })
  },
  setThreadSelectedID: (threadSelectedID: number) => {
    set({ threadSelectedID })
  },
  setNewThreadID: (newThreadID: number) => {
    set({ newThreadID })
  },
  setThreadList: (threadList: IThreadList[]) => {
    set({ threadList })
  },
  setBatchSizeSelected: (batchSizeSelected: number) => {
    set({ batchSizeSelected })
  },
  setCheckpointImg2ImgSelected: (checkpointImg2ImgSelected: NewCheckpointType) => {
    set({ checkpointImg2ImgSelected })
  },
  setCheckpointProfile: (checkpointProfile: CheckpointProfileType) => {
    set({ checkpointProfile })
  },
  setEditThreadNameID: (editThreadNameID: number) => {
    set({ editThreadNameID })
  },
  setCountNewThread: (countNewThread: number) => {
    set({ countNewThread })
  }
}))
