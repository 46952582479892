import axios from 'axios'
import { RequestGenerateLoraType, RequestImg2ImgType } from '../../types/media_type'
import moment from 'moment'
import BaseAPIs from './BaseAPI'

export default class CommonAPIs extends BaseAPIs {
  static endpoints = {
    login: this.baseURL + '/ai-img/login',
    checkpoint: this.baseURL + '/train-lora/lora/checkpoints',
    newCheckpoint: this.baseURL + '/train-lora/lora/new-checkpoints',
    requestTrainLora: this.baseURL + '/train-lora/lora',
    loraStatusJob: this.baseURL + '/train-lora/lora',
    requestText2Img: this.baseURL + '/ai-img/description',
    mediaStatusJob: this.baseURL + '/ai-img/job-data',
    renderHistory: this.baseURL + '/ai-img/media-by-user',
    renderHistoryJobList: this.baseURL + '/ai-img/proccessing-job',
    requestImg2Img: this.baseURL + '/ai-img/jewelry',
    img2imgStatusJob: this.baseURL + '/ai-img/jewelry/job-data',
    checkpointProfile: this.baseURL + '/ai-img/jewelry/checkpoint',
    updateCheckpointProfile: this.baseURL + '/ai-img/jewelry/setup-checkpoint',
    requestReGenerationImg2Img: this.baseURL + '/ai-img/jewelry/regeneration',
    categories: this.baseURL + '/ai-img/fabric-of-clothes/categories',
    fabrics: this.baseURL + '/ai-img/fabric-of-clothes/fabrics',
    generateFabricAIImg: this.baseURL + '/ai-img/fabric-of-clothes',
    renderMask: 'https://cla-sam.snafty.jp/render_mask',
    lorasTrainedHistory: this.baseURL + '/train-lora/lora',
    pointClick: 'https://cla-sam.snafty.jp/point_click',
    trainLoraJobList: this.baseURL + '/train-lora/lora/lora-processing',
    checkShowIntroPopup: this.baseURL + '/ai-img/intro-popup',
    getMySecondsTime: this.baseURL + '/ai-img/points/time-second',
    sendConfirmRechargeToSlack: this.baseURL + '/ai-img/payment-health-check',
    getMyTemplates: this.baseURL + '/ai-img/template',
    createTemplate: this.baseURL + '/ai-img/template',
    createProductContent: this.baseURL + '/ai-img/product-content',
    getDescriptionHistory: this.baseURL + '/ai-img/product-content',
    getRenderDescriptionHistory: this.baseURL + ''
  }

  static async login(email: string, password: string) {
    try {
      const formData = new FormData()
      formData.append('email', email)
      formData.append('password', password)

      let response = await axios.post(this.endpoints.login, formData, {
        headers: this.headers
      })
      console.log('======== LOGIN: ', response.data)
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async getCheckpointList() {
    const token = localStorage.getItem('token')
    console.log(token)

    try {
      let response = await axios.get(CommonAPIs.endpoints.checkpoint, {
        headers: { ...this.headers, Authorization: 'Bearer ' + token }
      })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async getNewCheckpointList() {
    const token = localStorage.getItem('token')

    try {
      let response = await axios.get(CommonAPIs.endpoints.newCheckpoint, {
        headers: { ...this.headers, Authorization: 'Bearer ' + token }
      })
      console.log('====== new checkpoints: ', response.data)
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static handleConvertBase64ToFile = (base64: string) => {
    const filename = 'image.jpg'
    const mimeType = 'image/jpeg'
    // Split the base64 string to get the data part
    const dataPart = base64.split(',')[1]

    // Convert the base64 data to a Blob
    const blob = atob(dataPart)
    const arrayBuffer = new ArrayBuffer(blob.length)
    const uint8Array = new Uint8Array(arrayBuffer)
    for (let i = 0; i < blob.length; i++) {
      uint8Array[i] = blob.charCodeAt(i)
    }

    // Create a Blob
    const blobObject = new Blob([uint8Array], { type: mimeType })

    // Create a File
    const file = new File([blobObject], filename, { type: mimeType })

    return file
  }

  static async requestTrainLora(inputDt: RequestGenerateLoraType) {
    const token = localStorage.getItem('token')

    console.log('checkPointSelected::', inputDt?.checkPointSelected)
    console.log('loraName:', inputDt?.name)

    const formData = new FormData()
    formData.append('name', inputDt?.name)
    formData.append('checkpoint', inputDt?.checkPointSelected)

    for (var i = 0; i < inputDt?.fileListBase64.length; i++) {
      console.log(`fileListBase64[${i}].des::`, this.handleConvertBase64ToFile(inputDt?.fileListBase64[i]?.url))
      formData.append(`images[${i}]`, this.handleConvertBase64ToFile(inputDt?.fileListBase64[i]?.url))
    }

    try {
      let response = await axios.post(CommonAPIs.endpoints.requestTrainLora, formData, {
        headers: { ...this.headers, Authorization: 'Bearer ' + token }
      })
      return Promise.resolve(response.data?.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async checkStatusLoraJob(jobID: number) {
    const token = localStorage.getItem('token')

    try {
      const url = `${CommonAPIs.endpoints.loraStatusJob}/${jobID}/status`
      let response = await axios.get(url, {
        headers: { ...this.headers, Authorization: 'Bearer ' + token }
      })
      return Promise.resolve(response.data?.data ?? [])
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async requestText2Img(descriptionText: string, checkPointSelected: string | undefined) {
    const token = localStorage.getItem('token')

    console.log('descriptionText:', descriptionText)
    console.log('checkPointSelected:', checkPointSelected)

    const formData = new FormData()
    formData.append(`descriptions[0]`, descriptionText)
    if (checkPointSelected) formData.append('checkpoint', checkPointSelected)

    try {
      let response = await axios.post(CommonAPIs.endpoints.requestText2Img, formData, {
        headers: { ...this.headers, Authorization: 'Bearer ' + token }
      })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async checkStatusMediaJob(jobID: number) {
    const token = localStorage.getItem('token')

    try {
      const url = `${CommonAPIs.endpoints.mediaStatusJob}/${jobID}/status`
      let response = await axios.get(url, {
        headers: { ...this.headers, Authorization: 'Bearer ' + token }
      })
      return Promise.resolve(response.data?.data ?? [])
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async getRenderHistory(startDate: string | Date, endDate: string | Date, page: number | string) {
    const token = localStorage.getItem('token')

    const startD = moment(startDate).format('YYYY-MM-DD')
    const endD = moment(endDate).format('YYYY-MM-DD')

    try {
      const url = `${CommonAPIs.endpoints.renderHistory}?start_date=${startD}&end_date=${endD}&page=${page}`
      let response = await axios.get(url, {
        headers: { ...this.headers, Authorization: 'Bearer ' + token }
      })
      return Promise.resolve(response.data?.data ?? [])
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async getRenderImgsProcessing() {
    const token = localStorage.getItem('token')

    try {
      let response = await axios.get(CommonAPIs.endpoints.renderHistoryJobList, {
        headers: { ...this.headers, Authorization: 'Bearer ' + token }
      })
      return Promise.resolve(response.data?.data ?? [])
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async requestImg2Img(inputDt: RequestImg2ImgType) {
    const token = localStorage.getItem('token')

    console.log('inputDt:', inputDt)

    const formData = new FormData()
    formData.append('type', inputDt?.type.toString())
    formData.append('checkpoint', inputDt?.checkPointSelected)
    formData.append('batch_size', inputDt?.batchSize.toString())

    if (inputDt?.threadId != 0) {
      formData.append('thread_id', inputDt?.threadId.toString())
    }

    for (var i = 0; i < inputDt?.fileListBase64.length; i++) {
      formData.append(`images[${i}]`, inputDt?.fileListBase64[i]?.url)
    }

    try {
      let response = await axios.post(CommonAPIs.endpoints.requestImg2Img, formData, {
        headers: { ...this.headers, Authorization: 'Bearer ' + token }
      })
      return Promise.resolve(response.data?.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async checkStatusImg2ImgJob(jobID: number) {
    const token = localStorage.getItem('token')

    try {
      const url = `${CommonAPIs.endpoints.img2imgStatusJob}/${jobID}/status`
      let response = await axios.get(url, {
        headers: { ...this.headers, Authorization: 'Bearer ' + token }
      })
      return Promise.resolve(response.data?.data ?? [])
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async getCheckpointProfile(checkpoint: string) {
    const token = localStorage.getItem('token')

    try {
      const url = `${CommonAPIs.endpoints.checkpointProfile}?checkpoint=${checkpoint}`
      let response = await axios.get(url, {
        headers: { ...this.headers, Authorization: 'Bearer ' + token }
      })
      return Promise.resolve(response.data?.data ?? [])
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async updateCheckpointProfile(checkpointSelected: string, checkpointProfile: string) {
    const token = localStorage.getItem('token')

    console.log('checkpointSelected:', checkpointSelected)
    console.log('checkpointProfile:', checkpointProfile)

    const formData = new FormData()
    formData.append('checkpoint', checkpointSelected)
    formData.append('profile', checkpointProfile)

    try {
      let response = await axios.post(CommonAPIs.endpoints.updateCheckpointProfile, formData, {
        headers: { ...this.headers, Authorization: 'Bearer ' + token }
      })
      return Promise.resolve(response.data ?? [])
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async requestReGenerationImg2Img(
    prompt_id: number,
    prompt: string,
    checkpoint: string,
    batch_size: number,
    thread_id: number
  ) {
    const token = localStorage.getItem('token')

    console.log('prompt_id:', prompt_id)
    console.log('prompt:', prompt)
    console.log('checkpoint:', checkpoint)
    console.log('batch_size:', batch_size)
    console.log('newThreadID:', thread_id)

    const formData = new FormData()
    formData.append('prompt_id', String(prompt_id))
    formData.append('prompt', prompt)
    formData.append('checkpoint', checkpoint)
    formData.append('batch_size', String(batch_size))
    formData.append('thread_id', String(thread_id))

    try {
      let response = await axios.post(CommonAPIs.endpoints.requestReGenerationImg2Img, formData, {
        headers: { ...this.headers, Authorization: 'Bearer ' + token }
      })
      return Promise.resolve(response.data ?? [])
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async getCategories() {
    const token = localStorage.getItem('token')

    try {
      let response = await axios.get(CommonAPIs.endpoints.categories, {
        headers: { ...this.headers, Authorization: 'Bearer ' + token }
      })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async getFabrics() {
    const token = localStorage.getItem('token')

    try {
      let response = await axios.get(CommonAPIs.endpoints.fabrics, {
        headers: { ...this.headers, Authorization: 'Bearer ' + token }
      })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async generateFabricAiImg(
    blendedImageFile: any,
    originalImageFile: any,
    maskImgFile: any,
    denoisingStrength: any,
    category: string,
    batch_size: string,
    fabric: any
  ) {
    const token = localStorage.getItem('token')

    const formData = new FormData()
    formData.append('pattern_img', blendedImageFile)
    formData.append('original_img', originalImageFile)
    formData.append('mask_img', maskImgFile)
    formData.append('denoising_strength', denoisingStrength)
    formData.append('category', category)
    formData.append('batch_size', batch_size)
    formData.append('fabric', fabric)

    try {
      let response = await axios.post(CommonAPIs.endpoints.generateFabricAIImg, formData, {
        headers: { ...this.headers, Authorization: 'Bearer ' + token }
      })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  static async renderMask(base64: string, x1: string, y1: string, x2: string, y2: string) {
    const formData = new FormData()
    formData.append('x1', `${parseInt(x1)}`)
    formData.append('x2', `${parseInt(x2)}`)
    formData.append('y1', `${parseInt(y1)}`)
    formData.append('y2', `${parseInt(y2)}`)
    formData.append('image', this.handleConvertBase64ToFile(base64))
    console.log('x1:', `${parseInt(x1)}`)
    console.log('x2:', `${parseInt(x2)}`)
    console.log('y1:', `${parseInt(y1)}`)
    console.log('y2:', `${parseInt(y2)}`)
    try {
      let response = await axios.post(CommonAPIs.endpoints.renderMask, formData, {
        headers: { ...this.headers }
      })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async getLoraTrainedHistory(startDate: string | Date, endDate: string | Date, page: number | string) {
    const token = localStorage.getItem('token')
    const startD = moment(startDate).format('YYYY-MM-DD')
    const endD = moment(endDate).format('YYYY-MM-DD')

    try {
      const url = `${CommonAPIs.endpoints.lorasTrainedHistory}?start_date=${startD}&end_date=${endD}&page=${page}`
      console.log('========URL: ', url)
      let response = await axios.get(url, {
        headers: { ...this.headers, Authorization: 'Bearer ' + token }
      })
      console.log('====== LORAS TRAINED HISTORY: ', response.data?.data)
      return Promise.resolve(response.data?.data ?? [])
    } catch (error) {
      console.log('====== LORAS TRAINED HISTORY ERROR: ', error)
    }
  }

  static async getDescriptionHistory(startDate: string | Date, endDate: string | Date, page: number | string) {
    const token = localStorage.getItem('token')
    const startD = moment(startDate).format('YYYY-MM-DD')
    const endD = moment(endDate).format('YYYY-MM-DD')

    try {
      const url = `${CommonAPIs.endpoints.getDescriptionHistory}?start_date=${startD}&end_date=${endD}&page=${page}`
      console.log('========URL: ', url)
      let response = await axios.get(url, {
        headers: { ...this.headers, Authorization: 'Bearer ' + token }
      })
      console.log('====== PRODUCT CONTENT HISTORY: ', response.data?.data)
      return Promise.resolve(response.data?.data ?? [])
    } catch (error) {
      console.log('====== PRODUCT CONTENT HISTORY ERROR: ', error)
    }
  }

  static async getRenderDescriptionHistory() {
    const token = localStorage.getItem('token')

    try {
      let response = await axios.get(CommonAPIs.endpoints.getRenderDescriptionHistory, {
        headers: { ...this.headers, Authorization: 'Bearer ' + token }
      })
      // console.log('======= train lora processing ==========')
      // console.log(response.data?.data)
      return Promise.resolve(response.data?.data ?? [])
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async inferencePointClick(base64: string, point: any) {
    const formData = new FormData()
    formData.append('x[]', `${parseInt(point.x)}`)
    formData.append('y[]', `${parseInt(point.y)}`)
    formData.append('image', this.handleConvertBase64ToFile(base64))
    console.log('formData', formData)
    try {
      let response = await axios.post(CommonAPIs.endpoints.pointClick, formData, {
        headers: { ...this.headers }
      })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async getLoraProcessing() {
    const token = localStorage.getItem('token')

    try {
      let response = await axios.get(CommonAPIs.endpoints.trainLoraJobList, {
        headers: { ...this.headers, Authorization: 'Bearer ' + token }
      })
      // console.log('======= train lora processing ==========')
      // console.log(response.data?.data)
      return Promise.resolve(response.data?.data ?? [])
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async checkShowIntroPopup(funcType: number) {
    const token = localStorage.getItem('token')

    try {
      let response = await axios.post(
        CommonAPIs.endpoints.checkShowIntroPopup,
        {
          function_type: funcType
        },
        {
          headers: { ...this.headers, Authorization: 'Bearer ' + token }
        }
      )
      // console.log('======= train lora processing ==========')
      // console.log(response.data)
      return Promise.resolve(response.data?.data ?? false)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async getMySecondsTime() {
    const token = localStorage.getItem('token')

    if (token == null || token == undefined || token == '') {
      return Promise.reject('Token is null or undefined')
    }

    try {
      let response = await axios.get(CommonAPIs.endpoints.getMySecondsTime, {
        headers: { ...this.headers, Authorization: 'Bearer ' + token }
      })
      console.log('======= getMySecondsTime ==========')
      console.log(response.data)
      return Promise.resolve(response.data?.data ?? 0)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async sendConfirmRechargeToSlack() {
    const token = localStorage.getItem('token')

    try {
      let response = await axios.post(
        CommonAPIs.endpoints.sendConfirmRechargeToSlack,
        {},
        {
          headers: { ...this.headers, Authorization: 'Bearer ' + token }
        }
      )
      console.log('======= Sent alert to slack confirm charge ==========')
      return Promise.resolve(response.data?.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async getMyTemplates() {
    const token = localStorage.getItem('token')

    try {
      let response = await axios.get(CommonAPIs.endpoints.getMyTemplates, {
        headers: { ...this.headers, Authorization: 'Bearer ' + token }
      })
      console.log('======= get my templates ==========')
      console.log(response.data)
      return Promise.resolve(response.data?.data?.data ?? [])
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async createTemplate(title: string, content: string, image: any) {
    try {
      const token = localStorage.getItem('token')

      const formData = new FormData()
      formData.append('title', title)
      formData.append('content', content)
      formData.append('thumbnail', image)

      const res = await axios.post(CommonAPIs.endpoints.createTemplate, formData, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
      return Promise.resolve(res.data)
    } catch (error) {
      console.log('Error creating template:', error)
      return Promise.reject(error)
    }
  }

  static async createProductContent(
    name: string,
    size: string,
    material: string,
    otherSpecifications: string,
    image: any,
    templateId: number
  ) {
    try {
      const token = localStorage.getItem('token')

      console.log('name:', name)
      console.log('size:', size)
      console.log('material:', material)
      console.log('otherSpecifications:', otherSpecifications)
      console.log('image:', image)
      console.log('templateId:', templateId)

      const formData = new FormData()
      formData.append('title', name)
      formData.append('size', size)
      formData.append('material', material)
      formData.append('other_specifications', otherSpecifications)
      formData.append('image', image)
      formData.append('template_id', `${templateId}`)

      const res = await axios.post(CommonAPIs.endpoints.createProductContent, formData, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
      return Promise.resolve(res.data?.data)
    } catch (error) {
      console.log('Error creating product content:', error)
      return Promise.reject(error)
    }
  }
}
