import React, { useContext, useEffect, useState } from 'react'
import { UserOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Breadcrumb, Button, Flex, Layout, Menu, Spin, Typography, theme } from 'antd'
import { Outlet, useNavigate, useLocation, Navigate } from 'react-router-dom'
import { AppContext } from '../../hooks/AppContext'
import Constant from '../../controller/Constant'
import logo from '../../assets/logo.png'
import HeaderCountdown from '../../components/common/HeaderCountdown'

const { Header, Content, Sider } = Layout

const SideBarMenu = [
  {
    label: 'ホーム ',
    key: Constant.keyScreen.home,
    icon: UserOutlined,
    route: '/'
  },
  {
    label: 'テキストからデザイン提案',
    key: Constant.keyScreen.txt2img,
    icon: UserOutlined,
    route: '/text2img'
  },
  {
    label: '参考画像からデザイン提案',
    key: Constant.keyScreen.img2img,
    icon: UserOutlined,
    route: '/img2img'
  },
  {
    label: 'ブランド学習',
    key: Constant.keyScreen.trainLora,
    icon: UserOutlined,
    route: '/train-lora'
  },
  {
    label: '生地シミュレーション',
    key: Constant.keyScreen.changeMaterial,
    icon: UserOutlined,
    route: '/change-material'
  },
  {
    label: '商品説明文生成',
    key: Constant.keyScreen.createProductContent,
    icon: UserOutlined,
    route: '/create-product-content'
  },
  {
    label: '履歴一覧',
    key: Constant.keyScreen.renderHistory,
    icon: UserOutlined,
    route: '/render-history'
  }
]

const BaseLayout = (props: any) => {
  const {
    token: { colorBgContainer, borderRadiusLG }
  } = theme.useToken()
  const navigate = useNavigate()
  const location = useLocation()

  const { menuSelected, setMenuSelected, isMobile } = useContext(AppContext)

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/login')
    }
  }, [])

  const activeMenuItem = SideBarMenu.find((item) => location.pathname == item.route)
  const selectedKeys = activeMenuItem ? [activeMenuItem.key.toString()] : []
  return (
    <Layout>
      <Header className='bg-white mb-2 h-[50px] text-[18px] font-bold'>
        <Flex align='center' justify='space-between'>
          <img
            src={logo}
            className='w-12 h-12 p-2'
            onClick={() => navigate('/')}
            //Setup icon khi hover vào img để hiện thị icon bàn tay
            style={{ cursor: 'pointer' }}
          />
          <HeaderCountdown isShowTime={location.pathname == '/render-history'} />
        </Flex>
      </Header>
      <Layout>
        <Sider
          width={226}
          style={{ background: 'white' }}
          // collapsible
          collapsed={isMobile}
          collapsedWidth={isMobile ? 70 : undefined}
        >
          <Menu
            mode='inline'
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            style={{
              height: 955,
              borderRight: 0,
              background: 'white',
              padding: 10,
              fontSize: 12
            }}
            // items={items2}
            selectedKeys={selectedKeys}
            onClick={(item) => {
              setMenuSelected(Number(item.key))
            }}
          >
            {SideBarMenu.map((e) => (
              <Menu.Item
                key={e.key}
                onClick={() => {
                  if (e.route == 'login') {
                    localStorage.clear()
                  }
                  navigate(e.route)
                }}
              >
                {e?.label}
              </Menu.Item>
            ))}
            <Flex vertical>
              <Typography
                onClick={() => {
                  localStorage.clear()
                  navigate('/login')
                }}
                className='text-center cursor-pointer'
                style={{ marginTop: 100 }}
              >
                ログアウト
              </Typography>
            </Flex>
          </Menu>
        </Sider>
        <Layout style={{ minHeight: window.innerHeight, paddingRight: 10 }}>
          <Breadcrumb>
            {/* <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>List</Breadcrumb.Item>
            <Breadcrumb.Item>App</Breadcrumb.Item> */}
          </Breadcrumb>
          <Content
            style={{
              margin: 0,
              minHeight: 280,
              borderRadius: borderRadiusLG,
              height: '100%'
            }}
          >
            {localStorage.getItem('token') ? <Outlet /> : <Navigate to='/login' replace />}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default BaseLayout
